var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services",attrs:{"id":"ServicesPage"}},[(_vm.kiosk.allow_queue && _vm.kiosk.allow_booking && !_vm.opted)?_c('div',[_c('SplittedScreen',{on:{"optedFor":_vm.optedFor}})],1):((_vm.kiosk.allow_booking && !_vm.opted) || _vm.opted == 'Booking')?_c('div',[_c('Booking',{on:{"optedFor":_vm.optedFor}})],1):_c('div',[(
        _vm.kiosk_config.multi_language && _vm.kiosk_config.multi_language != 'false'
      )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col language_select",staticStyle:{"margin":"10px 25px -10px 25px !important"}},[_c('LbrxLanguageSelector',{on:{"change":_vm.loadQueues}})],1)]):_vm._e(),_c('div',{staticClass:"row",style:(_vm.kiosk_config.multi_language && _vm.kiosk_config.multi_language != 'false'
          ? ''
          : 'margin-top: 40px;margin-bottom: 20px;')},[_c('div',{staticStyle:{"color":"#000"}}),_c('div',{staticClass:"col"},[_c('h2',{staticClass:"service_h",style:(_vm.kiosk_language == 'ar'
              ? 'letter-spacing: 0 !important;font-size: 35px;letter-spacing: 0 !important;font-family: Noto Sans Arabic, sans-serif;'
              : 'font-size: 20px;')},[_vm._v(" "+_vm._s(_vm.$t("Services.Message"))+" ")])])]),_c('form',{staticClass:"container services_container",style:(_vm.kiosk_config.multi_language && _vm.kiosk_config.multi_language != 'false'
          ? ''
          : 'max-height: 65vh !important;'),attrs:{"id":"servicesForm","method":"GET","action":"Ticket"}},[_c('div',{staticClass:"row"},[(_vm.services.length == 1)?_c('div',{staticClass:"col-md-3"}):_vm._e(),_vm._l((_vm.services),function(item,key){return _c('div',{key:key,class:_vm.services.length >= 6 ? 'col-md-4 service' : 'col-md-6 service',style:(!_vm.queueTargetedForServices ||
            _vm.queueTargetedForServices == item.queue_id
              ? ''
              : 'pointer-events: none;opacity: .5;')},[(!item.child.length)?_c('div',{staticStyle:{"display":"initial"}},[_c('LbrxService',{ref:"subServiceInput",refInFor:true,attrs:{"name":item.title,"size":"small","theme":"small","hover":"false","value":item},on:{"checked":_vm.addSelection,"unchecked":function($event){return _vm.removeSelection(item)}}})],1):_c('div',{staticStyle:{"display":"initial"},on:{"click":function($event){_vm.ServicesChild = item.child;
              _vm.togglepopupSubServices(true);}}},[_c('LbrxSubService',{ref:"subServiceInput",refInFor:true,attrs:{"name":item.title,"size":"small","theme":"small","hover":"false","value":item,"services":_vm.selectedServices}})],1)])})],2)]),_c('LoadingPopup',{attrs:{"active":_vm.loading.active,"message":_vm.loading.message}}),_c('div',{staticClass:"row bottom-btns"},[_c('div',{staticClass:"col"},[_c('LbrxButton',{directives:[{name:"long-press",rawName:"v-long-press",value:(3000),expression:"3000"}],attrs:{"name":this.kiosk.allow_queue && this.kiosk.allow_booking
              ? _vm.$t('Ticket.Return')
              : '',"size":"medium","theme":"dark","hover":"false","href":"#"},on:{"long-press-start":_vm.onLongPressStart,"click":function($event){_vm.opted = null}}})],1),_c('div',{staticClass:"col"},[_c('LbrxButton',{attrs:{"name":"","size":"medium","theme":"dark","hover":"false","href":"#"}})],1),(_vm.kiosk_config.allow_multiple_services == true)?_c('div',{staticClass:"col",on:{"click":function($event){return _vm.submitSelectedServices()}}},[_c('LbrxButton',{attrs:{"name":!this.Config.kiosk.allow_sms_ticket &&
            this.Config.kiosk.allow_print_ticket
              ? _vm.$t('Ticket.PrintTicket')
              : _vm.$t('Services.Next'),"size":"medium","theme":"light","hover":"true","href":"javascript:;"}})],1):_vm._e()]),_c('Popup',{attrs:{"message":_vm.popup.message,"hint":_vm.popup.hint,"title":_vm.popup.title,"type":_vm.popup.type,"confirmationButton":_vm.popup.confirmation,"active":_vm.popup.active},on:{"update:active":function($event){return _vm.$set(_vm.popup, "active", $event)},"confirm":function($event){_vm.popup.callback ? _vm.popup.callback : _vm.hidePopup()}}}),_c('PopupProblem',{attrs:{"message":_vm.popupProblem.message,"hint":_vm.popupProblem.hint,"title":_vm.popupProblem.title,"type":_vm.popupProblem.type,"confirmationButton":_vm.popupProblem.confirmation,"active":_vm.popupProblem.active},on:{"update:active":function($event){return _vm.$set(_vm.popupProblem, "active", $event)},"confirm":function($event){_vm.popupProblem.callback ? _vm.popupProblem.callback : _vm.hidePopup()}}}),_c('PopupQte',{attrs:{"item":_vm.popup_qte.item,"message":_vm.popup_qte.message,"hint":_vm.popup_qte.hint,"title":_vm.popup_qte.title,"type":_vm.popup_qte.type,"confirmationButton":_vm.popup_qte.confirmation,"active":_vm.popup_qte.active},on:{"update:active":function($event){return _vm.$set(_vm.popup_qte, "active", $event)},"confirm":_vm.addSelection}}),_c('PopupSubServices',{ref:"popupSub",attrs:{"kiosk_config":_vm.kiosk_config,"services":_vm.ServicesChild,"queueTargetedForServices":_vm.queueTargetedForServices,"selectedServices":_vm.selectedServices,"active":_vm.popupSubServicesState},on:{"update:active":function($event){_vm.popupSubServicesState=$event},"closepopupSubServices":_vm.togglepopupSubServices,"confirmSubServices":_vm.togglepopupSubServices,"addSelection":_vm.addSelection,"removeSelection":_vm.removeSelection}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="services" id="ServicesPage">
    <div v-if="kiosk.allow_queue && kiosk.allow_booking && !opted">
      <SplittedScreen @optedFor="optedFor" />
    </div>
    <div v-else-if="(kiosk.allow_booking && !opted) || opted == 'Booking'">
      <Booking @optedFor="optedFor" />
    </div>
    <div v-else>
      <div
        class="row"
        v-if="
          kiosk_config.multi_language && kiosk_config.multi_language != 'false'
        "
      >
        <div
          class="col language_select"
          style="margin: 10px 25px -10px 25px !important"
        >
          <LbrxLanguageSelector @change="loadQueues"></LbrxLanguageSelector>
        </div>
      </div>
      <div
        class="row"
        :style="
          kiosk_config.multi_language && kiosk_config.multi_language != 'false'
            ? ''
            : 'margin-top: 40px;margin-bottom: 20px;'
        "
      >
        <div style="color: #000"></div>
        <div class="col">
          <h2
            class="service_h"
            :style="
              kiosk_language == 'ar'
                ? 'letter-spacing: 0 !important;font-size: 35px;letter-spacing: 0 !important;font-family: Noto Sans Arabic, sans-serif;'
                : 'font-size: 20px;'
            "
          >
            {{ $t("Services.Message") }}
          </h2>
        </div>
      </div>
      <form
        id="servicesForm"
        class="container services_container"
        method="GET"
        action="Ticket"
        :style="
          kiosk_config.multi_language && kiosk_config.multi_language != 'false'
            ? ''
            : 'max-height: 65vh !important;'
        "
      >
        <div class="row">
          <div class="col-md-3" v-if="services.length == 1"></div>
          <div
            :class="
              services.length >= 6 ? 'col-md-4 service' : 'col-md-6 service'
            "
            v-for="(item, key) in services"
            :key="key"
            :style="
              !queueTargetedForServices ||
              queueTargetedForServices == item.queue_id
                ? ''
                : 'pointer-events: none;opacity: .5;'
            "
          >
            <div style="display: initial" v-if="!item.child.length">
              <LbrxService
                ref="subServiceInput"
                :name="item.title"
                size="small"
                theme="small"
                hover="false"
                :value="item"
                @checked="addSelection"
                @unchecked="removeSelection(item)"
              >
              </LbrxService>
            </div>
            <div
              style="display: initial"
              @click="
                ServicesChild = item.child;
                togglepopupSubServices(true);
              "
              v-else
            >
              <LbrxSubService
                ref="subServiceInput"
                :name="item.title"
                size="small"
                theme="small"
                hover="false"
                :value="item"
                :services="selectedServices"
              >
              </LbrxSubService>
            </div>
            <!-- v-long-press="3000" @long-press-start="openQtePopup(item)" -->
          </div>
        </div>
      </form>

      <LoadingPopup
        :active="loading.active"
        :message="loading.message"
      ></LoadingPopup>
      <div class="row bottom-btns">
        <div class="col">
          <LbrxButton
            :name="
              this.kiosk.allow_queue && this.kiosk.allow_booking
                ? $t('Ticket.Return')
                : ''
            "
            size="medium"
            theme="dark"
            hover="false"
            href="#"
            v-long-press="3000"
            @long-press-start="onLongPressStart"
            @click="opted = null"
          ></LbrxButton>
        </div>
        <div class="col">
          <LbrxButton
            name=""
            size="medium"
            theme="dark"
            hover="false"
            href="#"
          ></LbrxButton>
        </div>
        <div
          class="col"
          v-if="kiosk_config.allow_multiple_services == true"
          v-on:click="submitSelectedServices()"
        >
          <LbrxButton
            :name="
              !this.Config.kiosk.allow_sms_ticket &&
              this.Config.kiosk.allow_print_ticket
                ? $t('Ticket.PrintTicket')
                : $t('Services.Next')
            "
            size="medium"
            theme="light"
            hover="true"
            href="javascript:;"
          >
          </LbrxButton>
        </div>
      </div>
      <Popup
        :message="popup.message"
        :hint="popup.hint"
        :title="popup.title"
        :type="popup.type"
        :confirmationButton="popup.confirmation"
        :active.sync="popup.active"
        @confirm="popup.callback ? popup.callback : hidePopup()"
      >
      </Popup>

      <PopupProblem
        :message="popupProblem.message"
        :hint="popupProblem.hint"
        :title="popupProblem.title"
        :type="popupProblem.type"
        :confirmationButton="popupProblem.confirmation"
        :active.sync="popupProblem.active"
        @confirm="popupProblem.callback ? popupProblem.callback : hidePopup()"
      >
      </PopupProblem>

      <PopupQte
        :item="popup_qte.item"
        :message="popup_qte.message"
        :hint="popup_qte.hint"
        :title="popup_qte.title"
        :type="popup_qte.type"
        :confirmationButton="popup_qte.confirmation"
        :active.sync="popup_qte.active"
        @confirm="addSelection"
      >
      </PopupQte>
      <PopupSubServices
        ref="popupSub"
        :kiosk_config="kiosk_config"
        :services="ServicesChild"
        :queueTargetedForServices="queueTargetedForServices"
        :selectedServices="selectedServices"
        :active.sync="popupSubServicesState"
        @closepopupSubServices="togglepopupSubServices"
        @confirmSubServices="togglepopupSubServices"
        @addSelection="addSelection"
        @removeSelection="removeSelection"
      >
      </PopupSubServices>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { store } from "../../_store";
import LbrxButton from "../../components/buttons/Button.vue";
import LbrxLanguageSelector from "../../components/LanguageSelector/LanguageSelector.vue";
import LbrxService from "../../components/Services/ServiceSelector.vue";
import LbrxSubService from "../../components/Services/SubServiceSelector.vue";
import { kioskService } from "../../_services";
import LoadingPopup from "../../components/popups/Loading";
import Popup from "../../components/popups/Popup.vue";
import PopupProblem from "../../components/popups/PopupProblem.vue";
import PopupQte from "../../components/popups/PopupServiceQte.vue";
import PopupSubServices from "../../components/popups/PopupSubServices.vue";
import LongPress from "vue-directive-long-press";
import SplittedScreen from "/src/components/buttons/SplittedScreen.vue";
import Booking from "/src/components/Booking/Index.vue";
import html2canvas from "html2canvas";
// import Vue from "vue";
//import $ from 'jquery'
var executed = false;
var virtualServices = [];

export default {
  name: "ServicesPage",
  data: () => ({
    ServicesChild: [],
    popupSubServicesState: false,
    queueTargetedForServices: null,
    secondary: "#6c757d",
    msg: "ServicesPage",
    key: "",
    secret: "",
    requestFailed: false,
    errors: [],
    queues: [],
    services: [],
    selectedServices: [],
    loading: {
      active: false,
      message: "",
    },
    popup: {
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: null,
    },
    popupProblem: {
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: null,
    },
    popup_qte: {
      item: {},
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: "qteSelected",
    },
    kiosk_language: localStorage.getItem("Language"),
    kiosk: JSON.parse(localStorage.getItem("kiosk")).kiosk,
    kiosk_config: JSON.parse(
      JSON.parse(localStorage.getItem("kiosk")).kiosk.config
    ),
    opted: null,
    _setTimeout: null,
    _setTimeoutRedirected: false,
    _pingInterval: null,
  }),
  components: {
    LoadingPopup,
    LbrxButton,
    LbrxLanguageSelector,
    LbrxService,
    LbrxSubService,
    Popup,
    PopupProblem,
    PopupQte,
    PopupSubServices,
    SplittedScreen,
    Booking,
  },
  directives: {
    "long-press": LongPress,
  },
  methods: {
    ...mapActions(["logout"]),
    // storeToWebSQL() {
    //   var localDatabase = openDatabase(
    //     "liberrex-offline",
    //     "1.0",
    //     "liberrex-offline",
    //     2 * 1024 * 1024
    //   );
    //   localDatabase.transaction(function (tx) {
    //     tx.executeSql("CREATE TABLE IF NOT EXISTS Config (id unique, data)");
    //     tx.executeSql("INSERT OR REPLACE INTO Config (id, data) VALUES (?,?)", [
    //       1,
    //       localStorage.getItem("kiosk"),
    //     ]);
    //   });
    //   //JSON.parse( localStorage.getItem('kiosk'))
    // },

    togglepopupSubServices: function(state) {
      if (!state) {
        //check if parent service should be checked
        this.services.forEach((o) => {
          let check = this.selectedServices.find(
            (el) => el.parent_service_id === o.service_id
          );

          if (check !== undefined) {
            this.$refs.subServiceInput[
              this.services.indexOf(o)
            ].checkStateService(o.id);
          } else {
            this.$refs.subServiceInput[
              this.services.indexOf(o)
            ].unCheckStateService(o.id);
          }
        });
      }
      this.popupSubServicesState = state;
      // check if sub service is checked
      if (state) {
        this.$refs.popupSub.checkState();
      }
    },
    optedFor(choice) {
      this.opted = choice;
    },
    qteSelected(item, qte = 1) {
      alert(item);
      alert(qte);
    },
    openQtePopup(item) {
      this.showPopupQte(
        item,
        "success",
        "Confirmation",
        "",
        "Combien de fois voulez-vous faire ce service",
        "Selectionner",
        this.hidePopupQte
      );
    },
    onLongPressStart() {
      kioskService
        .refreshkiosk()
        .then(function() {})
        .catch(function(err) {
          console.log(err);
        })
        .finally(
          function() {
            this.$router.go();
          }.bind(this)
        );
      //   localStorage.removeItem("kiosk");
      //   this.$router.push("/login");
    },
    logout() {
      localStorage.removeItem("kiosk");
      this.$router.push("/login");
    },
    loadQueues() {
      this.queueTargetedForServices = null;

      let kio = JSON.parse(localStorage.getItem("kiosk")).kiosk;
      let conf = JSON.parse(kio.config);
      let queues = conf.queues.toString().split(",");

      //let queues = JSON.parse(this.kiosk_info.kiosk.config).queues.toString().split(',');
      this.services = [];
      if (localStorage.getItem("Language") == "en") {
        this.showLoading("Please wait, we are loading the available services");
      } else if (localStorage.getItem("Language") == "ar") {
        this.showLoading("يرجى الانتظار ، جاري تحميل الخدمات المتاحة");
      } else {
        this.showLoading(
          "Veuillez patienter, nous chargeons les services disponibles"
        );
      }
      queues.forEach((id) => {
        kioskService
          .getQueueById(id)
          .then(
            function(data) {
              data.services.forEach(
                function(obj) {
                  obj.queue_id = data.queue_id;
                  if (obj.child.length > 0) {
                    obj.child.forEach((el) => (el.queue_id = data.queue_id));
                  }
                  this.services.push(obj);
                }.bind(this)
              );
            }.bind(this)
          )
          .catch(function() {}.bind(this))
          .finally(
            function() {
              this.hideLoading();
            }.bind(this)
          );
      });
    },
    showLoading(message) {
      this.loading = {
        active: true,
        message: message,
      };
    },
    hideLoading() {
      this.loading = { active: false, message: "" };
    },
    addSelection(item, qte = 1) {
      this.removeSelection(item);
      item.qte = qte;
      this.selectedServices.push(item);
      this.queueTargetedForServices = item.queue_id;
      if (this.kiosk_config.allow_multiple_services == false) {
        this.submitSelectedServices();
      }
    },
    removeSelection(item) {
      let filteredServices = this.selectedServices.filter(function(obj) {
        return obj.id != item.id;
      });
      this.selectedServices = filteredServices;
      if (!this.selectedServices.length) {
        this.queueTargetedForServices = null;
      }
    },
    submitSelectedServices() {
      if (this.selectedServices.length) {
        localStorage.setItem(
          "selectedServices",
          JSON.stringify(this.selectedServices)
        );
        this.$router.push("/Ticket").catch(function() {});
      } else {
        if (localStorage.getItem("Language") == "en") {
          this.showPopupProblem(
            "danger",
            "danger",
            "Something went wrong",
            "Please select at least one service to continue",
            "Close",
            this.hidePopup
          );
        } else if (localStorage.getItem("Language") == "ar") {
          this.showPopupProblem(
            "danger",
            "danger",
            "هناك خطأ ما",
            "الرجاء تحديد خدمة واحدة على الأقل للمتابعة",
            "اغلاق",
            this.hidePopup
          );
        } else {
          this.showPopupProblem(
            "danger",
            "danger",
            "Un problème est survenu",
            "Veuillez sélectionner au moins un service pour continuer",
            "Fermer",
            this.hidePopup
          );
        }
      }
    },
    showPopup(type, title, message, hint, confirmation, callback) {
      this.popup = {
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    showPopupProblem(type, title, message, hint, confirmation, callback) {
      this.popupProblem = {
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    hidePopup() {
      this.popup = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },
    hidePopupProblem() {
      this.popupProblem = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },

    showPopupQte(item, type, title, message, hint, confirmation, callback) {
      this.popup_qte = {
        item: item,
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    hidePopupQte() {
      this.popup_qte = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },
    redirectionHandler() {
      clearTimeout(this._setTimeout);
      console.log("_timeOut Cleared!");

      this._setTimeout = setTimeout(
        function() {
          console.log("_timeOut Fired!");
          if (this.selectedServices.length && !this._setTimeoutRedirected) {
            this.submitSelectedServices();
            this._setTimeoutRedirected = true;
          }
        }.bind(this),
        10000
      );
    },
    ping() {
      try {
        html2canvas(document.body, {
          scale: 1, // scale canvas to 2x
        }).then(function(canvas) {
          canvas.toBlob(
            function(blob) {
              let form = new FormData();
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function() {
                form.append("screenshot", reader.result);
                console.log("Blob size:", blob, "bytes");
                kioskService
                  .ping(form)
                  .then(function() {}.bind(this))
                  .catch(
                    function(err) {
                      console.log(err);
                    }.bind(this)
                  );
              };
            },
            "image/jpeg",
            0.02
          ); // compress with JPEG and quality of 40%
        });
      } catch (err) {
        console.log(err);
      }
    },
    sendPing() {
      // console.log('zeaeazea', this.$moment().minutes(), store.getters.getPingSentStatus)
      if (this.$moment().minutes() % 5 == 0) {
        if (!store.getters.getPingSentStatus) {
        this.ping();
        // console.log('ping sent ...')
        this.updatePingSentStatus(true)
        }
      } else {
        this.updatePingSentStatus(false)
      }
    },
    ...mapActions(['setPingInterval', 'updatePingSentStatus']),
  },
  computed: {
    ...mapState({
      kiosk_info: (state) => state.Kiosk.kiosk,
      theme_info: (state) => state,
    }),
  },
  created() {},
  mounted() {
    // ping to server
    // this._pingInterval = setInterval(() => {this.sendPing()}, 5000)
    // this.setPingInterval(this._pingInterval)

    // this.storeToWebSQL();
    if (!executed) {
      this.loadQueues();
      executed = true;
      virtualServices = this.services;
    } else {
      this.services = virtualServices;
    }

    // refresh display with teller
    this.$pusher.channel("private-kiosk-" + this.kiosk.id).listen(
      ".kiosk-refresh",
      function() {
        kioskService
          .refreshkiosk()
          .then(function() {})
          .catch(function(err) {
            console.log(err);
          })
          .finally(
            function() {
              // close channel before leaving so duplicates don't happen
              this.$pusher.leaveChannel("private-kiosk-" + this.kiosk.id);
              // closing setInterval for same reason
              clearInterval(this._pingInterval);
              if (this.$route.name == "Home") {
                this.$router.go();
              } else if (this.$route.name == "Maintenance") {
                this.$router.go(-1);
              } else {
                this.$router.push("/home");
              }
            }.bind(this)
          );
      }.bind(this)
    );

    // auto logout display
    this.$pusher.channel("private-kiosk-" + this.kiosk.id).listen(
      ".kiosk-logout",
      function() {
        clearInterval(this._pingInterval);
        this.logout();
      }.bind(this)
    );

    // change maintenance
    this.$pusher.channel("private-kiosk-" + this.kiosk.id).listen(
      ".maintenance-change",
      function() {
        kioskService
          .refreshkiosk()
          .then(function() {})
          .catch(function(err) {
            console.log(err);
          })
          .finally(
            function() {
              // close channel before leaving so duplicates don't happen
              this.$pusher.leaveChannel("private-kiosk-" + this.kiosk.id);
              // closing setInterval for same reason
              clearInterval(this._pingInterval);
              if (this.$route.name == "Home") {
                this.$router.go();
              } else if (this.$route.name == "Maintenance") {
                this.$router.go(-1);
              } else {
                this.$router.push("/home");
              }
            }.bind(this)
          );
      }.bind(this)
    );

    if (this.kiosk && this.kiosk.maintenance) {
      this.$router.push("/maintenance");
    }
  },
  beforeMount() {
    if (this.kiosk_config.default_language) {
      this.$i18n.locale = this.kiosk_config.default_language;
      this.kiosk_language = this.kiosk_config.default_language;
      localStorage.setItem("Language", this.kiosk_config.default_language);
    } else {
      this.$i18n.locale = "fr";
      this.kiosk_language = "fr";
      localStorage.setItem("Language", "fr");
    }

    if (!this.kiosk_config.multi_language) {
      this.kiosk_config.multi_language = true;
    }
  },
};
</script>

<style scoped>
.service {
  padding-top: 10px;
}
</style>
